function preloaderFadeOutInit() {
  $(".preloader").delay(1000).fadeOut("slow");
  $("body").attr("id", "");
}
// Window load function
jQuery(window).on("load", function () {
  (function ($) {
    preloaderFadeOutInit();
  })(jQuery);
});

import AnimatedLinksObjs from "./animatedLink";

[...document.querySelectorAll("a.menu__link")].forEach((el) => {
  const elPosition = [...el.parentNode.children].indexOf(el);
  const fxObj = AnimatedLinksObjs[elPosition];
  fxObj && new fxObj(el);
});

//bckground music
const button = document.querySelector("#playerbackground");
const icon = document.querySelector("#playerbackground > i");
let audio = document.querySelector("audio");

button.addEventListener("click", () => {
  if (audio.paused) {
    audio.volume = 1;
    audio.play();
    icon.classList.remove("fa-volume-up");
    icon.classList.add("fa-volume-mute");
  } else {
    audio.pause();
    icon.classList.remove("fa-volume-mute");
    icon.classList.add("fa-volume-up");
  }
  player.classList.add("fade");
});

//soundlibrary
$(document).ready(function () {
  var playing = false;

  /*// Add file names.
  $(".play-button").each(function () {
    var $button = $(this);
    var $audio = $button.find("audio");

    $($("<span>").text($audio.attr("src"))).insertBefore($audio);
  }); */

  // Add click listener.
  $(".play-button").click(function () {
    var $button = $(this);
    var audio = $button.find("audio")[0]; // <-- Interact with this!

    // Toggle play/pause
    if (playing !== true) {
      audio.play();
    } else {
      audio.pause();
      audio.currentTime = 0;
    }

    // Flip state
    $button.toggleClass("playing");
    playing = !playing;
  });
});

const room = document.querySelector(".soundlibrary");
const btns = document.querySelectorAll(".btn");

room.addEventListener("click", (e) => {
  btns.forEach((btn) => {
    if (btn.getAttribute("id") === e.target.getAttribute("id"))
      btn.classList.add("active");
    else btn.classList.remove("active");
  });
});
